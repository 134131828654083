import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '15, 53, 75',
		'primary-dark': '11, 36, 51',
		'accent': '232, 36, 68',
		'accent-plus': '255, 255, 255',
	},
});
