export default {
	cinema: {
		address: 'Московское шоссе, 3К',
		place: 'ТРЦ Алания Молл',
		phoneNumbers: ['8 (8672) 303-301', '8 (8672) 303-302'],
		facebook: null,
		instagram: null,
		vk: 'https://m.vk.com/alaniacinema',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CCaLYXij',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
